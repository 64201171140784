import React from "react";
import { Route } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import Menu from "./Menu";
import StreetsmartForm from "./StreetsmartForm";

import streetsmartWordmark from "../Images/streetsmartv2-reverse.png";

// Declaring these inline so we can package the background image w/ Webpack and not have to fool with different paths in produciton and dev environments

export default function Main(props) {
  return (
    <React.Fragment>
      <Menu />
      <div className="main-background">
        <div className="container">
          <Card style={{ maxWidth: "500px", minWidth: "300px" }}>
            <CardBody>
              <div className="streetsmart-header">
                <img
                  src={streetsmartWordmark}
                  alt="STREETSMART v2 wordmark in white"
                />
              </div>

              <Route path="/:action" component={StreetsmartForm} />

            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
