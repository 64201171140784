import React from "react";
import { Formik } from "formik";
import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Button
} from "reactstrap";
import { number, object } from "yup";
import { getFlirSerialNumber } from "../Utility/streetsmart";
import { Switch, Route } from "react-router-dom";

const validationSchema = object().shape({
  serialNumber: number()
    .min(10000, "STREETSMARTV2 serial numbers begin at 10000")
    .max(999999, "The serial number entered is too large")
    .typeError(
      "A STREETSMARTV2 serial number will not contain letters or characters"
    )
    .required("Please provide a serial number")
});

function redirectOnSubmit(serialNumber, action) {
  const flirSerialNumber = getFlirSerialNumber(serialNumber);

  const urlPath = action === "configure" ? "cabinet/configure" : action;

  const serialPrefix = action === "configure" ? "SSP" : "";

  window.location.href = `https://streetsmart.gridsmart.com/${urlPath}/${serialPrefix}${flirSerialNumber}`;
}

function renderForm(action, serial) {
  serial = serial || "";
  let copy = "",
    buttonText = "";
  switch (action) {
    case "configure":
      buttonText = "Configure";
      copy = (
        <>
          To configure your STREETSMART<i>V2</i>, enter the last 5 digits of the
          serial number found on the back of the device.
        </>
      );
      break;

    case "status":
      buttonText = "Check Status";
      copy = (
        <>
          To check the status of your STREETSMART<i>V2</i>, enter the last 5
          digits of the serial number found on the back of the device.
        </>
      );
      break;

    default:
      return <div>Looks like you went the wrong way!</div>;
  }
  return (
    <>
      <p className="lead">{copy}</p>
      <Formik
        initialValues={{ serialNumber: serial }}
        validationSchema={validationSchema}
        onSubmit={values => {
          const { serialNumber } = values;
          redirectOnSubmit(serialNumber, action);
        }}
        render={props => {
          const {
            handleSubmit,
            handleChange,
            values,
            handleBlur,
            errors,
            dirty,
            isValid
          } = props;
          console.log(errors);
          console.log(dirty);
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="serialNumber">Serial Number</Label>
                <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">SS2-</InputGroupAddon>
                  <Input
                    invalid={errors.serialNumber}
                    autoFocus
                    id="serialNumber"
                    type="text"
                    placeholder="00000"
                    name="serialNumber"
                    value={values.serialNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormFeedback>{errors.serialNumber}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button type="submit" disabled={!dirty || !isValid}>
                  {buttonText}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
}

export default function StreetsmartForm({ match }) {
  const action = match.params.action;

  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${match.url}/(SS2-?)?:serial(\\d{5})`}
          render={({ match }) => {
            redirectOnSubmit(match.params.serial, action);
          }}
        />
        <Route
          path={`${match.url}/(SS2-?)?:serial(.*)`}
          render={({ match }) => renderForm(action, match.params.serial)}
        />
        <Route path={`${match.url}`} render={() => renderForm(action)} />
      </Switch>
    </div>
  );
}
