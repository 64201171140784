import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Collapse
} from "reactstrap";

import wordmark from "../Images/asphalt-wordmark.png";

function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Navbar className="custom-navbar" expand="md">
      <NavbarBrand href="/">
        <img
          height="30"
          src={wordmark}
          alt="GRIDSMART Wordmark in Intrepid yellow"
        />
      </NavbarBrand>
      <NavbarToggler
        className="custom-toggler"
        onClick={() => setIsOpen(!isOpen)}
      />

      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Link to="/configure" className="nav-link">
              Configure
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/status" className="nav-link">
              Status
            </Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Menu;
