import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./Components/Main";

import "./Styles/index.scss";

const App = () => (
  <Router>
    <Main />
  </Router>
);

export default App;
